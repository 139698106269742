/* eslint-disable prettier/prettier */
<template>
  <div>
    <headers></headers>
    <!-- 广告 -->
    <div class="banner w">
      <img src="../../assets/img/user_head.png" alt="" />
    </div>
    <!-- 主体 -->
    <div class="content w d-flex justify-between">
      <!-- 导航 -->
      <div class="nav">
        <div
          v-if="isSHow"
          class="user d-flex flex-column justify-center align-center"
        >
          <img
            v-if="user.icon && user.icon != ''"
            class="user-avatar"
            :src="user.icon"
            alt=""
          />
          <img
            v-else
            class="user-avatar"
            src="../../assets/img/ava.png"
            alt=""
          />
          <div class="user-name">{{ user.rname }}</div>
        </div>
        <div class="nav-index cursor" @click="goTo('/')">首页</div>
        <ul class="nav-item">
          <li class="nav-title">我的课程</li>
          <li
            class="nav-items d-flex align-center cursor"
            @click="goTo('start')"
            :class="{ nav_active: url == 'start' }"
          >
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/class1.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/class1_active.png"
                alt=""
              />
            </div>
            开始听课
          </li>
          <li
            class="nav-items d-flex align-center cursor"
            @click="goTo('record')"
            :class="{ nav_active: url == 'record' }"
          >
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/class2.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/class2_active.png"
                alt=""
              />
            </div>
            听课记录
          </li>
          <!-- <li
            class="nav-items d-flex align-center cursor"
            @click="goTo('myLive')"
            :class="{ nav_active: url == 'myLive' }"
          >
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/class3.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/class3_active.png"
                alt=""
              />
            </div>
            我的直播
          </li> -->
          <!-- <li
            class="nav-items d-flex align-center cursor"
            @click="goTo('classCollect')"
            :class="{ nav_active: url == 'classCollect' }"
          >
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/class4.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/class4_active.png"
                alt=""
              />
            </div>
            课程收藏
          </li> -->
        </ul>
        <ul class="nav-item">
          <li class="nav-title">我的题库</li>
          <li
            class="nav-items d-flex align-center cursor"
            @click="goTo('question')"
            :class="{ nav_active: url == 'question' }"
          >
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/tiku1.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/tiku1_active.png"
                alt=""
              />
            </div>
            开始做题
          </li>
          <li
            class="nav-items d-flex align-center cursor"
            @click="goTo('collect')"
            :class="{ nav_active: url == 'collect' }"
          >
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/tiku2.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/tiku2_active.png"
                alt=""
              />
            </div>
            试题收藏
          </li>
          <li
            class="nav-items d-flex align-center cursor"
            @click="goTo('wrong')"
            :class="{ nav_active: url == 'wrong' }"
          >
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/tiku4.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/tiku4_active.png"
                alt=""
              />
            </div>
            错题集
          </li>
          <li
            class="nav-items d-flex align-center cursor"
            @click="goTo('annal')"
            :class="{ nav_active: url == 'annal' }"
          >
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/tiku3.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/tiku3_active.png"
                alt=""
              />
            </div>
            做题记录
          </li>
        </ul>
        <ul class="nav-item">
          <li class="nav-title">个人管理</li>
          <li
            class="nav-items d-flex align-center cursor"
            @click="goTo('myOrder')"
            :class="{ nav_active: url == 'myOrder' }"
          >
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/user1.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/user1_active.png"
                alt=""
              />
            </div>
            我的订单
          </li>
          <!-- <li class="nav-items d-flex align-center cursor">
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/user2.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/user2_active.png"
                alt=""
              />
            </div>
            我的积分
          </li> -->
          <li
            class="nav-items d-flex align-center cursor"
            @click="goTo('account')"
            :class="{ nav_active: url == 'account' }"
          >
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/user3.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/user3_active.png"
                alt=""
              />
            </div>
            账户安全
          </li>
        </ul>
        <ul class="nav-item">
          <li class="nav-title">其他</li>
          <li
            class="nav-items d-flex align-center cursor"
            @click="goTo('information')"
            :class="{ nav_active: url == 'information' }"
          >
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/other1.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/other1_active.png"
                alt=""
              />
            </div>
            我的资料
          </li>
          <!-- <li class="nav-items d-flex align-center cursor">
            <div class="nav-items-img">
              <img class="img1" src="../../assets/img/other2.png" alt="" />
              <img
                class="img2"
                src="../../assets/img/other2_active.png"
                alt=""
              />
            </div>
            系统消息
          </li> -->
        </ul>
      </div>
      <!-- 主体右边 -->
      <div class="content-right">
        <router-view></router-view>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      user: "",
      isSHow: false
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.url = route.name;
      },
      immediate: true
    }
  },
  created() {
    let user = this.$store.state.user;
    let users = JSON.parse(window.localStorage.getItem("user"));
    console.log(user, users);
    if (user) {
      this.isSHow = true;
      this.user = user;
    } else if (users) {
      this.isSHow = true;
      this.user = users;
    } else {
      this.isSHow = false;
    }
  },
  mounted() { },
  methods: {
    goTo(id) {
      console.log(this.url);
      if (this.isSHow) {
        if (this.url != id) {
          this.url = id;
          this.$router.push(id);
          const currentY =
            document.documentElement.scrollTop || document.body.scrollTop;
          this.scrollAnimation(currentY, 0);
        }
      } else {
        this.$route.replace("/login");
        window.localStorage.clear();
      }

    },
    scrollAnimation(currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let _currentY = currentY;
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
        }
      }, 1);
    }
  }
};
</script>

<style lang="less" scoped>
//导航选中状态
.nav_active {
  background: #f6f9ff;
  border-color: #5f2eff !important;
  .img1 {
    opacity: 0 !important;
  }
  .img2 {
    top: 0 !important;
  }
}

//广告
.banner {
  height: 130px;
  margin-top: 20px;
}
//主体
.content {
  margin-top: 20px;
  //导航
  .nav {
    width: 180px;
    background: #ffffff;
    padding: 24px 0px 8px 0px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
    height: 100%;
    .user {
      .user-avatar {
        width: 61px;
        height: 61px;
        border-radius: 50%;
      }
      .user-name {
        font-size: 14px;
        color: #333333;
        margin-top: 15px;
      }
    }
    .nav-index {
      padding-left: 35px;
      box-sizing: border-box;
      color: #333333;
      font-size: 16px;
      margin-top: 34px;
      margin-bottom: 16px;
    }
    .nav-item {
      // padding-left: 19px;
      padding-bottom: 8px;
      box-sizing: border-box;

      .nav-title {
        font-size: 16px;
        color: #333333;
        border-top: 1px dashed #e7e8ef;
        padding: 16px 0px 9px 20px;
        margin: 0 16px;
        box-sizing: border-box;
      }
      .nav-items {
        font-size: 14px;
        color: #666666;
        width: 100%;
        height: 30px;
        padding-left: 35px;
        box-sizing: border-box;
        border-left: 4px solid transparent;
        .nav-items-img {
          height: 14px;
          width: 14px;
          margin-right: 5px;
          position: relative;
          overflow: hidden;
          .img1 {
            display: block;
            transition: 0.5s;
            -moz-transition: 0.5s;
            -webkit-transition: 0.5s;
            -o-transition: 0.5s;
          }
          .img2 {
            position: absolute;
            top: -14px;
            left: 0;
            transition: 0.5s;
            -moz-transition: 0.5s;
            -webkit-transition: 0.5s;
            -o-transition: 0.5s;
          }
        }
        &:hover .img1 {
          opacity: 0;
        }

        &:hover .img2 {
          top: 0;
        }
      }
    }
  }
  // 主体右边
  .content-right {
    width: 1000px;
  }
}
</style>
